import './../App.css';
import { Fragment, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
    Bars3Icon,
    XMarkIcon,
    ShoppingCartIcon,
} from '@heroicons/react/24/outline';
import Cart from './Cart';
import Notification from './Notification';
import { showConfirm } from './Message';
import { showSuccess } from './Message';
import { useDispatch } from 'react-redux';
import { doLogOut } from '../actions/authAction';
import { getUserLogin } from '../Helpers';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
    //Cart State
    const [openCart, setOpenCart] = useState(false);
    const handleOnCloseCart = () => setOpenCart(false);
    //Notification State
    const [openNotif, setOpenNotif] = useState(false);
    const handleOnCloseNotif = () => setOpenNotif(false);
    //Dispatch
    const dispatch = useDispatch();
    //Navigate
    const navigate = useNavigate();
    const location = useLocation(); // once ready it returns the 'window.location' object
    const userLogin = getUserLogin();
    const navigation = [
        { name: 'Dashboard', to: '/' },
        { name: 'Transaction', to: '/transaction' },
        {
            name: userLogin ? 'Hello ' + userLogin.name : 'Login/Signup',
            to: userLogin ? '/profile' : '/login',
        },
        { name: 'Checkout', to: '/checkout' },
    ];

    const doSignOut = () => {
        showConfirm(async function (confirmed) {
            if (confirmed) {
                dispatch(doLogOut());
                showSuccess('Signout Success!');
                navigate('/login');
            }
        });
    };

    return (
        <>
            <Disclosure as='nav' className='bg-gray-800'>
                {({ open }) => (
                    <>
                        <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
                            <div className='relative flex h-16 items-center justify-between'>
                                <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                                    {/* Mobile menu button*/}
                                    <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                                        <span className='sr-only'>
                                            Open main menu
                                        </span>
                                        {open ? (
                                            <XMarkIcon
                                                className='block h-6 w-6'
                                                aria-hidden='true'
                                            />
                                        ) : (
                                            <Bars3Icon
                                                className='block h-6 w-6'
                                                aria-hidden='true'
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                                    <div className='flex flex-shrink-0 items-center'>
                                        <img
                                            className='block h-8 w-auto lg:hidden'
                                            src='/logo/Logo Non BG Purple.png'
                                            alt='Juragan Akun'
                                        />
                                        <img
                                            className='hidden h-8 w-auto lg:block'
                                            src='/logo/Logo Non BG Purple.png'
                                            alt='Juragan Akun'
                                        />
                                    </div>
                                    <div className='hidden sm:ml-6 sm:block'>
                                        <div className='flex space-x-4'>
                                            {navigation.map((item) => (
                                                <NavLink
                                                    key={item.name}
                                                    to={item.to}
                                                    className={classNames(
                                                        item.to ===
                                                            location.pathname
                                                            ? 'bg-gray-900 text-white'
                                                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                        'rounded-md px-3 py-2 text-sm font-medium'
                                                    )}
                                                    aria-current={
                                                        item.to ===
                                                        location.pathname
                                                            ? 'page'
                                                            : undefined
                                                    }
                                                >
                                                    {item.name}
                                                </NavLink>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                                    <button
                                        type='button'
                                        className='rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                                        onClick={() => setOpenCart(true)}
                                    >
                                        <span className='sr-only'>
                                            View notifications
                                        </span>
                                        <ShoppingCartIcon
                                            className='h-6 w-6'
                                            aria-hidden='true'
                                        />
                                    </button>
                                    <Notification
                                        visible={openNotif}
                                        onClose={handleOnCloseNotif}
                                    />

                                    {/* Profile dropdown */}
                                    <Menu as='div' className='relative ml-3'>
                                        <div>
                                            <Menu.Button className='flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                                                <span className='sr-only'>
                                                    Open user menu
                                                </span>
                                                <img
                                                    className='h-8 w-8 rounded-full'
                                                    src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                                                    alt=''
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter='transition ease-out duration-100'
                                            enterFrom='transform opacity-0 scale-95'
                                            enterTo='transform opacity-100 scale-100'
                                            leave='transition ease-in duration-75'
                                            leaveFrom='transform opacity-100 scale-100'
                                            leaveTo='transform opacity-0 scale-95'
                                        >
                                            <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                                {userLogin ? (
                                                    <>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <NavLink
                                                                    to='/profile'
                                                                    className={classNames(
                                                                        active
                                                                            ? 'bg-gray-100'
                                                                            : '',
                                                                        'block px-4 py-2 text-sm text-gray-700'
                                                                    )}
                                                                >
                                                                    Your Profile
                                                                </NavLink>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <NavLink
                                                                    // to='/settings'
                                                                    className={classNames(
                                                                        active
                                                                            ? 'bg-gray-100'
                                                                            : '',
                                                                        'block px-4 py-2 text-sm text-gray-700'
                                                                    )}
                                                                >
                                                                    Settings
                                                                </NavLink>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <p
                                                                    onClick={
                                                                        doSignOut
                                                                    }
                                                                    className={classNames(
                                                                        active
                                                                            ? 'bg-gray-100'
                                                                            : '',
                                                                        'block px-4 py-2 text-sm text-gray-700'
                                                                    )}
                                                                >
                                                                    Sign out
                                                                </p>
                                                            )}
                                                        </Menu.Item>
                                                    </>
                                                ) : (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <NavLink
                                                                to='/login'
                                                                className={classNames(
                                                                    active
                                                                        ? 'bg-gray-100'
                                                                        : '',
                                                                    'block px-4 py-2 text-sm text-gray-700'
                                                                )}
                                                            >
                                                                Login
                                                            </NavLink>
                                                        )}
                                                    </Menu.Item>
                                                )}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Disclosure>

            <Cart visible={openCart} onClose={handleOnCloseCart} />
        </>
    );
}
